import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useColorModeValueKey} from 'hooks/useColors';

// import zhCN from 'date-fns/locale/zh-CN';
// import zhTW from 'date-fns/locale/zh-TW';
// import enUS from 'date-fns/locale/en-US';
import DateField from './DateField';
import {parseDate, parseDateTime} from '@internationalized/date';
import {format} from 'date-fns';
import {isDate, isNil} from 'lodash';
import {
    Box,
    Button,
    HStack,
    IconButton,
    Popover,
    PopoverAnchor,
    PopoverBody,
    PopoverContent,
    Stack,
    Text,
} from '@chakra-ui/react';
import CalendarIcon from './Icon/Calendar';
import {
    I18nProvider,
    useButton,
    useDatePicker,
    useDateRangePicker,
    useDialog,
} from 'react-aria';
import {useDatePickerState, useDateRangePickerState} from 'react-stately';
import Calendar, {RangeCalendar} from './Calander';
import {AriaPopover} from './AriaPopover';

const Dialog = ({title, children, ...props}) => {
    const ref = React.useRef();
    const {dialogProps} = useDialog(props, ref);

    return (
        <div {...dialogProps} ref={ref}>
            {children}
        </div>
    );
};

// only support default value, no control mode
const DateRangeSelector = (props) => {
    const {
        isDisabled,
        // value,
        defaultValue,
        onChangeValue,
        flex = 1,
        minDate,
        maxDate,
        variant = 'outlined',
        granularity,
    } = props;
    const {t, i18n} = useTranslation('app');
    const formaterCalanderRangeDate = (date) => {
        return {
            start: date?.start
                ? parseDateTime(format(date.start, "yyyy-MM-dd'T'HH:mm:ss"))
                : null,
            end: date?.end
                ? parseDateTime(format(date.end, "yyyy-MM-dd'T'HH:mm:ss"))
                : null,
        };
    };
    const formaterCalanderDate = (date) => {
        return parseDateTime(format(date, "yyyy-MM-dd'T'HH:mm:ss"));
    };
    const ref = useRef();
    const buttonRef = useRef();
    const processedProps = {
        ...props,
        value: undefined,
        defaultValue: formaterCalanderRangeDate(defaultValue),
        onChange: (date) => {
            console.log(2, date);
            if (!isNil(date)) {
                if (date.start.year >= 1 && date.end.year >= 1) {
                    onChangeValue({
                        start: date.start.toDate(),
                        end: date.end.toDate(),
                    });
                }
            }
        },
        minValue:
            minDate && isDate(minDate) ? formaterCalanderDate(minDate) : null,
        maxValue:
            maxDate && isDate(maxDate) ? formaterCalanderDate(maxDate) : null,
    };
    const state = useDateRangePickerState({
        ...processedProps,
        shouldCloseOnSelect: false,
    });
    const {
        groupProps,
        labelProps,
        startFieldProps,
        endFieldProps,
        buttonProps: nButtonProps,
        dialogProps,
        calendarProps,
    } = useDateRangePicker(processedProps, state, ref);

    const {buttonProps} = useButton(nButtonProps, buttonRef);
    return (
        <HStack flex={flex}>
            <Popover
                placement="bottom-start"
                isOpen={state.isOpen}
                onClose={state.close}
                isLazy
                autoFocus={false}
            >
                <PopoverAnchor>
                    <HStack flex={1} ref={ref} spacing={{base: 1, md: 3}}>
                        <DateField
                            {...startFieldProps}
                            InputRightElement={
                                <Box pr={1}>
                                    <IconButton
                                        variant={'ghost'}
                                        {...buttonProps}
                                        size="xs"
                                        ref={buttonRef}
                                        isDisabled={isDisabled}
                                        icon={<CalendarIcon boxSize={4} />}
                                    />
                                </Box>
                            }
                            // InputLeftElement={InputLeftElement}
                            variant={variant}
                        />
                        <Text>-</Text>
                        <DateField
                            {...endFieldProps}
                            InputRightElement={
                                <Box pr={1}>
                                    <IconButton
                                        variant={'ghost'}
                                        {...buttonProps}
                                        size="xs"
                                        ref={buttonRef}
                                        isDisabled={isDisabled}
                                        icon={<CalendarIcon boxSize={4} />}
                                    />
                                </Box>
                            }
                            // InputLeftElement={InputLeftElement}
                            variant={variant}
                        />
                    </HStack>
                </PopoverAnchor>
                <PopoverContent
                    width={{base: 320, md: 640}}
                    py={2}
                    overflow={'hidden'}
                    boxShadow={'lg'}
                    borderColor={useColorModeValueKey('border')}
                >
                    <PopoverBody>
                        <I18nProvider locale={i18n.language}>
                            <RangeCalendar {...calendarProps} />
                        </I18nProvider>
                        <HStack
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mt={2}
                        >
                            <Button
                                variant={'ghost'}
                                size={'xs'}
                                onClick={state.close}
                            >
                                {t('close')}
                            </Button>
                            <Button
                                variant={'ghost'}
                                size={'xs'}
                                onClick={() => {
                                    onChangeValue({
                                        start: null,
                                        end: null,
                                    });
                                    state.setValue({
                                        start: null,
                                        end: null,
                                    });
                                }}
                            >
                                {t('clear')}
                            </Button>
                        </HStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </HStack>
    );
};

export default DateRangeSelector;
