import {formatDate, formatDateTime} from 'utils/formator';
import CountrySelector from './formComponent/CountrySelector';
import CurrencySelector from './formComponent/CurrencySelector';
import DatePicker from './formComponent/DatePicker';
import FileUploader from './formComponent/FileUploader';
import OptionSelector from './formComponent/OptionSelector';
import NetworkSelector from './formComponent/NetworkSelector';
import SectionHeading from './formComponent/SectionHeading';
import TextInput from './formComponent/TextInput';
import FileViewer from './viewerComponent/FileViewer';

import TextViewer from './viewerComponent/TextViewer';
import ViewerSectionHeading from './viewerComponent/ViewerSectionHeading';
import DisplayText from './formComponent/DisplayText';
import PersonTitleSelector from './formComponent/PersonTitleSelector';
import ButtonGroup from './formComponent/ButtonGroup';
import DateRangeSelector from './formComponent/DateRangeSelector';
import CountrySelectorViewer from './viewerComponent/CountrySelectorViewer';
import PhoneNumberInput from './formComponent/PhoneNumberInput';
import TransferNeedsInput from './formComponent/TransferNeedsInput';
import TransferNeedsViewer from './viewerComponent/TransferNeedsViewer';
import { replace } from 'lodash';
import IndustrySelector from './formComponent/IndustrySelector';
import BusinessIndustrySelectorViewer from './viewerComponent/BusinessIndustrySelectorViewer';
import RolesSelector from './formComponent/RolesSelector';
import RolesSelectorViewer from './viewerComponent/RolesSelectorViewer';
import FormBankSearchInput from './formComponent/FormBankSearchInput';
import PasswordInput from './formComponent/PasswordInput';
import CheckBox from './formComponent/CheckBox';

export const viewComponents = {
    textInput: {
        // added
        component: TextViewer,
    },
    textArea: {
        component: TextViewer,
    },
    displayText: {
        component: TextViewer,
    },
    dateSelector: {
        //added
        component: TextViewer,
        formater: (v) => (v ? formatDate(v) : null),
    },
    dateTimeSelector: {
        //added
        component: TextViewer,
        formater: (v) => (v ? formatDateTime(v) : null),
    },
    dateRangeSelector: {
        //added
        component: TextViewer,
        formater: (v) =>
            v?.start && v?.end
                ? `${formatDate(v.start)} - ${formatDate(v.end)}`
                : null,
    },
    fileUploader: {
        //added
        component: FileViewer,
    },
    countrySelector: {
        //added
        component: CountrySelectorViewer,
    },

    optionSelector: {
        component: TextViewer,
    },
    currencySelector: {
        component: TextViewer,
    },
    networkSelector: {
        component: TextViewer,
    },
    phoneNumberInput: {
        component: TextViewer,
        formater: (v) => (v ? replace(v, '@', ' ') : null),
    },
    transferNeedsInput: {
        component: TransferNeedsViewer,
    },
    personTitleSelector: {
        component: TextViewer,
    },
    sectionHeading: {
        component: ViewerSectionHeading,
    },
    buttonGroup: {
        component: TextViewer,
    },
    businessIndustrySelector: {
        component: BusinessIndustrySelectorViewer,
    },
    rolesSelector: {
        component: RolesSelectorViewer,
    },
    bankSearchInput: {
        component: TextViewer,
    },
    passwordInput: {
        component: TextViewer,
    },
    checkbox: {
        component: TextViewer,
        formater: (v) => (v ? 'Yes' : 'No'),
    },
};

export const formComponents = {
    textInput: {
        // added
        component: TextInput,
        defaultValue: '',
    },
    textArea: {
        // added
        component: TextInput,
        defaultValue: '',
        textArea: true,
    },
    countrySelector: {
        //added
        component: CountrySelector,
        defaultValue: '',
    },
    dateSelector: {
        //added
        component: DatePicker,
        defaultValue: null,
        granularity: 'day',
    },
    dateTimeSelector: {
        //added
        component: DatePicker,
        defaultValue: null,
        granularity: 'minute',
    },
    dateRangeSelector: {
        //added
        component: DateRangeSelector,
        defaultValue: {
            start: null,
            end: null,
        },
    },
    phoneNumberInput: {
        component: PhoneNumberInput,
    },
    transferNeedsInput: {
        component: TransferNeedsInput,
    },
    optionSelector: {
        // added
        component: OptionSelector,
        defaultValue: null,
    },
    networkSelector: {
        component: NetworkSelector,
        defaultValue: null,
    },
    sectionHeading: {
        // added
        component: SectionHeading,
    },
    fileUploader: {
        // added
        component: FileUploader,
        defaultValue: '',
    },
    currencySelector: {
        //added
        component: CurrencySelector,
        defaultValue: '',
    },
    displayText: {
        //added
        component: DisplayText,
    },
    personTitleSelector: {
        //added
        component: PersonTitleSelector,
        defaultValue: '',
    },
    buttonGroup: {
        component: ButtonGroup,
    },
    businessIndustrySelector: {
        component: IndustrySelector,
    },
    rolesSelector: {
        component: RolesSelector,
        defaultValue: [],
    },
    bankSearchInput: {
        component: FormBankSearchInput,
    },
    passwordInput: {
        component: PasswordInput,
    },
    checkbox: {
        component: CheckBox,
        defaultValue: false,
    },
    displayPhoneNumberInput: {
        component: DisplayText,
        formater: (v) => (v ? replace(v, '@', ' ') : null),
    },
};
